.app-view {
  position: relative;
  margin: 40px auto 70px auto;
  padding: 0;

  @media (min-width: $desktop-xs) {
    display: flex;
    justify-content: center;
    padding: 0 $spacing-m;
    margin: 90px auto 120px auto;
  }

  @media (min-width: $desktop-m) {
    padding: 0 $spacing-l;
  }

  &__img-wrapper {
    position: relative;
    width: 80%;
    min-height: auto;
    margin: 0 auto;
    margin-top: -60px;

    @media (min-width: $desktop-xs) {
      margin-top: -110px;
    }
  }

  .rectangle-grid {
    position: absolute;
    z-index: 1;
    top: 70%;
    max-width: 100%;
    mix-blend-mode: overlay;
    opacity: 0.7;
  }

  .glowing-square {
    margin-top: 10%;
  }
}

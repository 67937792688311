.features-presentation {
  max-width: 1920px;
  margin: 100px 3% 0 3%;

  @media (min-width: $desktop-m) {
    padding: 0;
    margin: 100px $section-gap 0 $section-gap;
  }

  .feature-card {
    &:last-child {
      margin-bottom: 50px;
    }
  }
}

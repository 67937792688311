.glowing-square {
  display: none;

  @media (min-width: $tablet-l) {
    display: block;
    position: absolute;
    z-index: -1;
    background: rgba(27, 186, 255, 0.2);
    transform: translate(-50%, -50%) rotate(45deg);
    transform-origin: 50% 50%;
    border-radius: 64px;
    filter: blur(100px);

    &--horizontal-center {
      left: 50%;
    }

    &--horizontal-left {
      top: 50%;
      left: 0;
    }

    &--horizontal-right {
      top: 50%;
      left: 100%;
    }

    &--vertical-center {
      top: 50%;
    }

    &--vertical-top {
      top: 0%;
    }

    &--vertical-bottom {
      top: 100%;
    }
  }
}

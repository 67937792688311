.download-os-images {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: $desktop-xs) {
    width: 100%;
    margin-right: 40px;
  }

  @media (min-width: $desktop-s) {
    margin-right: 60px;
  }

  @media (min-width: $desktop-l) {
    margin-right: 80px;
  }

  &__list-title {
    padding: 0 $spacing-m;
    color: map-get($font, 'grey');
    text-transform: uppercase;
    font-size: $font-s-4;

    @media (min-width: $desktop-xs) {
      padding: 0;
    }
  }

  &__list {
    .download-card:first-child {
      margin-bottom: 25px;
    }
  }

  &__list-link {
    width: fit-content;
    margin: 50px auto 0 auto;

    @media (min-width: $desktop-xs) {
      margin: 50px 0 0 0;
    }
  }

  .section-title {
    margin: 25px 0 40px 0;
  }
}

.rental-features-presentation {
  max-width: 1920px;
  margin: 100px 3% 0 3%;

  @media (min-width: $desktop-m) {
    padding: 0;
    margin: 100px $section-gap 0 $section-gap;
  }

  .feature-description__description {
    margin-top: 16px;

    @media (min-width: $desktop-m) {
      line-height: 30px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: $font-m-6;
    font-weight: 700;
    margin-bottom: 68px;

    @media (min-width: $mobile-xl) {
      font-size: $font-l-2;
    }

    @media (min-width: $desktop-xs) {
      font-size: $font-l-3;
    }

    @media (min-width: $desktop-s) {
      font-size: $font-l-4;
    }
  }
}

.breadcrumbs {
  &__list {
    display: flex;
    list-style: none;

    &-item {
      display: flex;
      align-items: center;

      &-symbol {
        margin: 0 8px;
      }

      &--active {
        color: map-get($font, 'blue');
      }
    }
  }
}

.how-to-rent {
  max-width: 1920px;
  margin: 50px 0;
  padding: 0 $spacing-xs;

  @media (min-width: $mobile-l) {
    margin: 50px 3% 0 3%;
    padding: 0 $spacing-s;
  }

  @media (min-width: $desktop-s) {
    margin: 50px $section-gap 0 $section-gap;
    padding: 0;
  }

  &-video {
    margin: 50px 0;
  }
}

.how-to-rent-page-hero {
  position: relative;

  .page-hero-background {
    display: flex;
    flex-direction: column;
    align-items: center;

    .breadcrumbs {
      margin-top: 2%;
      margin-left: 4%;
      align-self: flex-start;
    }
  }

  .page-hero {
    padding: 8%;

    &__header {
      font-weight: bold;
    }

    &__description {
      max-width: 1024px;
    }
  }

  .rectangle-grid {
    position: absolute;
    z-index: 1;
    top: 40%;
    max-width: 100%;
    mix-blend-mode: overlay;
    opacity: 0.7;
  }
}

.how-to-rent-tutorial {
  &__step {
    position: relative;
    margin-bottom: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $desktop-m) {
      gap: 18px;
    }

    &-header {
      text-align: center;
      margin-bottom: 30px;

      p {
        color: map-get($font, 'blue-light');
        text-transform: uppercase;
        font-size: $font-s-1;

        @media (min-width: $desktop-m) {
          font-size: $font-s-2;
        }

        @media (min-width: $desktop-xl) {
          font-size: $font-s-3;
        }
      }

      h2 {
        font-size: $font-m-4;

        @media (min-width: $desktop-m) {
          font-size: $font-m-6;
        }

        @media (min-width: $desktop-xl) {
          font-size: $font-l-1;
        }
      }
    }

    &-list {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 100px;

      @media (min-width: $mobile-l) {
        gap: 44px;
      }

      @media (min-width: $desktop-s) {
        flex-direction: row;
      }
    }

    &-message {
      margin: 30px auto 0 auto;
      padding: 30px 35px;
      font-size: $font-m-2;
      text-align: center;
      gap: 22px;
      width: 70%;
      max-width: 750px;
      border-radius: 32px 32px 0 0;
      background: map-get($background, 'navy-gradient-transparent-100');

      @media (min-width: $desktop-s) {
        margin: 40px auto 0 auto;
        padding: 45px 40px;
        font-size: $font-m-4;
        text-align: center;
      }
    }
  }
}

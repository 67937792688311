.feature-list-swiper {
  margin: 0 auto;
  max-width: 1800px;

  .section-title {
    display: flex;
    margin-bottom: 0;

    &__description {
      margin: 0;
    }

    @media (min-width: $tablet-xl) {
      align-items: start;
      text-align: start;
    }
  }

  &__content-wrapper {
    max-width: 1844px;
    margin: 100px 3% 0 3%;

    @media (min-width: $desktop-m) {
      padding: 0;
      margin: 100px $section-gap 0 $section-gap;
    }
  }

  .rectangle-grid-vertical {
    position: absolute;
    mix-blend-mode: overlay;
    min-height: 620px;

    @media (min-width: $desktop-xs) {
      width: 100%;
      height: 100%;
      min-width: 100%;
      min-height: 100%;
    }
  }

  &__description-wrapper {
    width: 100%;
    margin: auto;
    margin-bottom: 60px;
    padding: 0 $spacing-s;

    @media (min-width: $desktop-xs) {
      padding: 0 $spacing-m;
    }

    @media (min-width: $desktop-m) {
      padding: 0 $spacing-l;
    }
  }

  &__header {
    font-size: $font-l-1;

    @media (min-width: $mobile-s) {
      font-size: $font-l-3;
    }

    @media (min-width: $desktop-s) {
      font-size: $font-l-4;
    }

    @media (min-width: $desktop-m) {
      font-size: $font-l-5;
    }
  }

  &__description {
    margin-top: 25px;
    font-size: $font-m-1;
    color: map-get($font, 'grey-light');

    @media (min-width: $mobile-s) {
      font-size: $font-m-3;
    }

    @media (min-width: $desktop-xs) {
      max-width: 60%;
    }

    @media (min-width: $desktop-m) {
      font-size: $font-m-4;
    }
  }

  &__show-all {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  &__show-all-link {
    position: relative;
    display: flex;
    align-items: flex-end;
    color: map-get($font, 'white');
    font-weight: 600;

    font-size: $font-s-3;
    gap: 8px;

    &:hover {
      color: map-get($font, 'blue-light');

      .arrow-right-redirect-icon {
        path {
          fill: map-get($font, 'blue-light');
        }
      }

      &::after {
        width: 100%;
      }
    }

    @media (min-width: $desktop-m) {
      font-size: $font-s-4;
    }
  }

  .swiper-content-wrapper {
    position: relative;
    align-self: center;
    width: 100%;
    max-width: 1800px;
  }

  .swiper {
    &-wrapper {
      width: 100%;
    }

    &-slide {
      width: fit-content;
    }

    &-card__background {
      background-color: map-get($background, 'navy');
      width: 100%;
      height: 339px;
      min-width: 321px;
      min-height: 339px;
      padding: 1px;
      clip-path: polygon(0% 0%, 76% 0%, 100% 18%, 100% 100%, 0% 100%);
      border-radius: 32px;

      @media (min-width: $desktop-xl) {
        min-width: 310px;
        max-width: 321px;
        min-height: 338px;
      }
    }

    &-card {
      width: 100%;
      height: 337px;
      clip-path: polygon(0% 0%, 76% 0%, 100% 18%, 100% 100%, 0% 100%);
      background: map-get($background, 'navy-dark');
      padding: 5% 10%;
      cursor: grab;
      border-radius: 32px;

      .card__description {
        margin-top: 20px;
      }
    }

    &-button {
      &-prev,
      &-next {
        position: initial;
        display: initial;
        z-index: 0;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        border: 2px solid;
        color: map-get($font, 'grey');
        background: map-get($background, 'navy-dark');
        cursor: pointer;
        margin-top: 0;

        @media (min-width: $mobile-xs) {
          width: 35px;
          height: 35px;
        }

        @media (min-width: $desktop-xs) {
          min-width: 50px;
          min-height: 50px;
        }

        &:hover {
          border: 2px solid map-get($border, 'blue');
          color: map-get($font, 'blue');
        }

        &::after {
          font-size: $font-s-3;
          font-weight: 700;
        }

        @media (min-width: $desktop-xs) {
          &::after {
            font-size: $font-s-4;
          }
        }
      }
    }

    &-button-disabled {
      display: none;
    }
  }

  .swiper-pagination {
    position: static;
    gap: 20px;
    margin-top: 16px;
    display: flex;
    justify-content: center;

    &-bullet {
      background: map-get($font, 'grey');
      opacity: 1;
      &-active {
        background: map-get($background, 'blue');
      }
    }

    @media (min-width: $tablet-xl) {
      display: none;
    }
  }

  &__content-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 40px;
    gap: 29px;

    @media (min-width: $tablet-xl) {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }

    &__buttons {
      display: none;

      @media (min-width: $tablet-xl) {
        display: flex;
        gap: 20px;
      }

      &--disabled {
        display: none;
      }
    }
  }
}

.tutorial-step-element {
  flex: 1;

  @media (min-width: $mobile-l) {
    padding: 20px;
    border: 1px solid map-get($border, 'navy');
    border-radius: 32px;
    background-image: map-get($background, 'navy-gradient-transparent-50');
  }

  @media (min-width: $desktop-xs) {
    padding: 25px;
    max-width: 740px;
  }

  &__image {
    border-radius: 4px;

    @media (min-width: $mobile-l) {
      border-radius: 16px;
    }
  }
}

.tutorial-step-element__content {
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: $desktop-m) {
    margin-top: 32px;
    gap: 16px;

    &-header {
      font-size: $font-m-6;
    }

    &-description {
      font-size: $font-m-1;
    }
  }

  &-header {
    font-weight: 400;
    font-size: $font-m-2;
  }

  &-description {
    font-size: $font-s-4;
    color: map-get($font, 'grey');
  }

  &-list {
    margin-left: 0.5em;

    li {
      margin-top: 10px;
      padding-left: 1em;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        transform: translateY(-50%);
        top: 12px;
        width: 0.25em;
        height: 0.25em;
        background-color: map-get($background, 'blue-light');
      }

      @media (min-width: $desktop-s) {
        font-size: $font-m-3;
        margin-bottom: 0.5rem;
      }
    }

    &--nested {
      color: map-get($font, 'grey');

      li {
        font-size: $font-s-4;
      }

      li::before {
        top: 10px;
        background-color: map-get($background, 'white');
      }
    }
  }
}

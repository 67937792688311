.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-size: $font-s-4;
  border-radius: 6px;
  transition:
    color 0.2s,
    background 0.2s;
  cursor: pointer;

  @media (min-width: $desktop-xs) {
    height: 34px;
    font-size: $font-s-3;
  }

  @media (min-width: $desktop-m) {
    height: 48px;
    font-size: $font-s-4;
  }
}

.btn-type-blue {
  color: map-get($font, 'white');
  background: map-get($background, 'blue');
  font-weight: 600;
  font-size: $font-s-4;

  &:hover {
    background: map-get($background, 'blue-light');
  }
}

.btn-type-white {
  color: map-get($font, 'black');
  background: map-get($background, 'white');

  &:hover {
    color: map-get($font, 'white');
    background: map-get($background, 'blue-light');
  }
}

.btn-type-border {
  color: map-get($font, 'white');
  border: solid 2px map-get($border, 'white');

  &:hover {
    color: map-get($font, 'blue-light');
    border-color: map-get($border, 'blue-light');
  }
}

.btn-type-redirect {
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  height: 38px;
  margin: auto;
  padding: 0 1rem;
  border-radius: 4px;

  @media (min-width: $tablet-xl) {
    height: 48px;
  }

  svg {
    align-self: center;
  }
}

.btn-sign-up {
  display: flex;
  width: fit-content;
  height: 48px;
  margin: auto;
  padding: 0 2.25rem;
  border-radius: 4px;
}
